@import url('https://fonts.googleapis.com/css2?family=Work+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cookie&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Patua+One&display=swap');
*{
    margin: 0;
    padding: 0;
    font-family: "Patua One", serif;
    font-weight: 400;
    font-style: normal;


    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    
}

.coupon-code{
    width: 150px !important;
}

.popup-banner {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    z-index: 1000000;
  }
  
  .popup-banner.active {
    display: flex;
  }
  
  .popup-content {
    display: flex;
      
    height: 80%;
    flex-direction: column;
    align-items: center;
    background-color: #333;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    position: relative;
    color: white;
  }
  
  .download-button {
    display: inline-block;
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 5px;
  }
  .popup-content img{
    width: 99%;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
  }
.top_navbar{
    position: sticky !important;
    z-index: 10000;
    top: 0;
}
.navcontainer3{
    background-color: white;
}

html,body{
width: 100%;
height: 100%;
overflow: visible;

}
body{
    background-color: rgb(243, 243, 243);
}

nav{
    
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(to right,rgb(255, 103, 31),orange, green);
}
#inputField:-webkit-autofill,
#inputField:-webkit-autofill:hover, 
#inputField:-webkit-autofill:focus, 
#inputField:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;

}
#inputField{
    background: transparent !important;
    border: none;
    outline: none;
    width: 90%;
}
.social{
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

}
.product-items img{
    width: 100%;
}
.address{
    display: flex;
    align-items: center;
    gap: 20px;
}
.footer-img{
    width: 200px;
    height: 200px;
    position: relative;
    bottom: 80px;
    right: 20px;
}
.nav-item6{
    border: 1px solid white;
    padding: 10px;
    position: relative;
    bottom: 120px;

    overflow-y: scroll;
    height: 200px;
    scrollbar-width: none;
    scrollbar-color: white rgb(33, 37, 41);
    
}

#search{

    background-color: transparent !important;
    cursor: pointer;
    border: none;
    outline: none;
    width: 100%;
    font-size: 22px;
}
.address a{
    text-decoration: none;
    color: white;
}
.social a{
   color: white;
    font-size: 17px;
    font-weight: bolder;
}
.social a:hover{
    color: #000;
}
.navcontainer{
    font-family: "Patua One", serif;
    font-weight: 400;
    font-style: normal;
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 40px 0px;
}
.navcontainer img{
    width: 250px;
}
.navcontainer .cart{
    display: flex;
    align-items: center;
    height: 100%;
    gap: 5px;
}
.navcontainer .cart a{
    text-decoration: none;
    width: 60px;
    height: 50px;
    background-color: #555252;
    display: flex;
    font-size: 25px;
    align-self: center;
    justify-content: center;
    border-radius: 5px;
    border: 2px solid rgb(196, 191, 191);
}
.navcontainer .cart a img{

    width: 40px;
    height: 40px;
}
.navcontainer2{
    width: 100%;
    font-family: "Patua One", serif;
    font-weight: 400;
    font-style: normal;
    height: 70px;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;

    background-image: linear-gradient(to right,rgb(255, 103, 31),orange,green);
}
.cart-link{
    display: flex;
    justify-content: center;
    align-items: center;
   color: orange;
    font-size: 22px;
  
}
.cart-link span{
    display: flex;
    justify-content: center;
    align-items: center;
   min-width: 20px;
    height: 20px;
    background-color: #242222;
    border-radius: 50%;
    border: 0.1px solid white;
}
.cart-link span sup{
    margin-top: 17px;
}
.navcontainer2 form{
    display: none;
    width: 90%;
}
.navcontainer2 .right-nav2{
    display: flex;
    align-items: center;
    gap: 20px;
}
.navcontainer2 a{
    display: flex;
    gap: 5px;
    align-items: center;
    font-size: 20px;
    color: white;
    font-weight: bolder;
    text-decoration: none;
}
.navcontainer3{
    height: 50px;
    display: flex;
    align-items: center;
    gap: 30px;
  
    justify-content: center;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.drop-down{
    display: none;
    position: absolute;
    z-index: 200;
    background-color: white;
    padding: 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
   

}
.drop-down a{
    font-size: 14px;
   font-weight: bolder;
    color: #000;
    
    padding: 5px 0px;
    text-decoration: none;
}
.drop-down a:hover{
    background-color: #618acf;
    color: white;
}
.navchild-3 button{
    display: flex;
    align-items: center;
    gap: 5px;
    border: none;
    outline: none;
    font-size: 22px;
    background-color: transparent;
}
.navchild-3:hover .drop-down{
display: flex;
flex-direction: column;

}
.navcontainer3 .cart-3{
    font-size: 22px;
    display: none;
    color: black;
    text-decoration: none;
}


.nav2-cross {
height: 100%;
display: flex;
align-items: center;
gap: 20px;

}
.nav2-cross a{
    color: black;
    font-size: 25px;
   
}
.nav2-fc{

    vertical-align: bottom;
}
.search-container{
    margin-top: 10px;
    padding: 30px;
    display: grid;
    width: 1250px;
    gap: 10px;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, minmax(105px, 200px));
    background-color: white;
}
  /* Our CSs */
  .term-condition {
    margin-top: 50px;
    text-align: justify;
    background-color: white;
      max-width: 800px;
      margin: 20px auto;
      padding: 50px;
    }
    .term-condition h1,.term-condition h2 {
      text-align: center;
    }
    .term-condition h2 {
      margin-top: 30px;
    }
    .term-condition ul {
      list-style-type: disc;
      padding-left: 20px;
    }
    .term-condition  p {
      margin-bottom: 20px;
    }
    .term-condition  ul li span{
      color: blue;
      font-weight: bolder;
    }






.alert{
    position: sticky;
    top: 0;
    z-index: 1000;
}


.grid-price1 h4{
    text-wrap: nowrap;
    font-size: 16px !important;
}










.topheading{
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    padding: 10px;
    position: sticky;
    top: 0px;
    z-index: 8;
    background-color: white;

}
.arrow-key{
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 50%;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}





.cards-slider .card{
    height: 470px;

}
#main-slider-container{
    margin-top: 10px;
    width: 1250px !important;
    height: 475px;
align-self: flex-start;
    position: relative;
  
}

.cards-slider::-webkit-scrollbar{
    display: none;
}
.cards-slider{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    scrollbar-width: none;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
}
.cards-slider .card{
    flex-grow: 0 !important;
    flex-shrink: 0 !important;
    flex-basis: 340px !important;
    margin-left: 20px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.cards-slider-button{
    
    width: 100%;
}
.cards-slider-button button{
    width: 40px;
    height: 40px;
    position: absolute;
    border-radius: 50%;
   
 
}
.cards-slider-button #left{
    right: 97%;
    top: 40%;
}
.cards-slider-button #right{
    left: 98%;
    top: 40%;
}


.testi-head{
    width: 450px;
    border-bottom: 10px solid rgb(81, 81, 221);
    border-bottom-right-radius: 23%;
    
}
.product-items{
   width: 80%;
   display: grid;
   grid-template-columns: repeat(auto-fit, minmax(105px, 200px));
   gap: 10px;
   

   
}
.req-form{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    gap: 30px;
}
.req-form form{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.req-form div{
display: flex;
flex-direction: column;

}
.text-secondary{
text-transform: uppercase;
}
.policy li{
    list-style: disclosure-closed;
}
.req-form div input{
    width: 350px;
    padding: 5px 10px;
}
.banner1{
    width: 1228px;
  
   margin: 15px 0px 0px 0px;
}
.gk{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.gk img{
    width:210px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
.product-add-page{
    width: 80%;
    display: flex;
    justify-content: center;
    gap: 80px;
    margin: 40px;
    background-color: white;
    padding: 30px;
}
.item-image{
    width: 380px;
    height: 480px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.select-image
{
    margin-top: 10px;
    display: flex;
    justify-content: center;
    gap: 10px;
}
.select-image img{
    padding: 2px;
    width: 40px;
    height: 50px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}
.select-image img:hover{
    opacity: 0.6;
}
.zoomed-image {
    background-size:cover;
    cursor:crosshair;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
 
 }

 .Right-Items{
    display: flex;
    flex-direction: column;
    gap: 20px;
 }
 .Right-Items .actual-price{
    color: rgb(0, 126, 255);

 }
 .Right-Items .Real-price{
    color: rgb(255, 0, 0);
    font-size: 22px;
    text-decoration:1.5px line-through;
 }
 .Right-Items .discount-percentage{
    color: rgb(12, 134, 12);
 }
 .Right-Items .Save-money
 {
    color: rgb(39, 200, 39);
 }
 .items-btn .items-first-child {
    display: flex;
   
    gap: 20px;
 }
 .items-btn .items-first-child p{
align-self: flex-end;
 }
 .items-btn .items-first-child span{
background-color: rgb(34, 177, 76);
color: white;
padding: 10px 20px;
font-weight: bolder;
 }
 .shipping-cost{
    display: flex;
  flex-wrap: wrap;
    align-items: center;
    gap: 15px;
    border-bottom: 1px solid rgb(207, 203, 203);
    border-top: 1px solid rgb(207, 203, 203);
    padding: 10px 0px;
 }
 .shipping-cost p{
    align-self: flex-end;
 }
 .shipping-cost img{
    width: 250px;
    height: 60px;
    border-radius: 23px;
 }
 .items-btn{
    display: flex ;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
    
    padding-bottom: 10px;
 }
 .items-second-child{
    display: flex;
    align-items: center;
    gap: 10px;
   
 }
 .items-second-child div input{
    padding: 5px 10px;
    width: 50px;
    background-color: white;
  border: none;
 
 }
 .items-second-child div button{
    padding: 5px 10px;
 }
 .items-offers{
    border-top: 1px solid rgb(207, 203, 203);
   

 }

 #deliverymode{
    padding: 10px 20px;
    cursor: pointer;
    background-color: white;
    border: none;
    font-size: 18px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
 }
 .crousal{
    width: 1228px;
    height: 800px;
   overflow-x: hidden;
   display: flex;
   align-items: center;
   scroll-behavior: smooth;
 }
 .crousal-big-container{
    position: relative;
 }
 .crousal .crousali-img{
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100%;
  
 }
 .crousal .crousali-img img{
    width: 100%;
    height: 50%;
 }
 .crousalbtn button{
    position: absolute;
   top: 45%;
   height: 100px;
   background-color: transparent;
   border: none;
   font-size: 62px;
   color: white;
 }
.crousalbtn #crou-right{
    left: 97%;
}
.title-hover:hover{
    color: #3468C0 !important;
}

 .offer-pack h5{
    font-weight: bolder;
    font-size: 25px;
    padding: 20px;
    text-align: center;
    background-color: rgb(238, 238, 238);
 }
 .offer-pack .offer-pack-container div
 {
   display: flex;
   justify-content: center;

   align-items: center;
    height: 200px;
    column-gap: 10px;
 }

 .offer-pack .offer-pack-container div .items{
    margin-top: 20px;
    width: 150px;
    height: 200px;
    border: 1px solid black;
 }
.small-cards{
    /* width: 200px; */
    height: 350px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    background-color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;

  
}

.signup-form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 350px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    padding: 30px;
    row-gap: 10px;
    background-color: white;
    font-weight: bolder;
    border-radius: 10px;
    
}

.signup-form .con1{
    display: flex;
    flex-direction: column;
    width: 300px;
   row-gap: 5px;
}
.signup-form .con1 input{
    width: 300px;
    padding: 5px 10px;
    border-radius: 2px;
    border: 2px solid white;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

}
.signup-form .con1 input:focus{
    border: 2px solid rgba(98, 98, 245, 0.926);
}

.signup-form .con2 ,.signup-form .con1{
    font-size: 13px;
  
} 





.ring-link{
    --bs-focus-ring-color: rgba(var(--bs-success-rgb), .25)
}

.small-cards img{
    width: 160px;
    height: 200px;
    margin-top: 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.error{
    width:400px;
}

.cart-card{
    margin-top: 20px;
    width: 1000px;
    background-color: white;
    height: 300px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
padding: 10px;
box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
font-family: "Patua One", serif;
font-weight: 400;
font-style: normal;


}
.cart-card img{
    width: 200px;
    height: 100%;
}
.continue{
    display: flex;
    flex-direction: row-reverse;
}
.card-container2{
    width: 1000px;
    height: 250px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    background-color: white;
   margin-bottom: 20px;
   padding: 10px;
}
.card-container2 .items{
    line-height: 10px;
}


.offer-des{
    width: 350px;
    font-size: 30px;
    line-height: 20px;
  
    font-family: cookie;
}
.coundown{
    width: 40px;
    padding: 2px;
}
.time-card .con1 h1{

    font-family: cookie;
    font-size: 55px !important;
    text-shadow: 1px 1px 20px black;
}
.time-card{


    width: 1228px;
    height: 400px;
   
    display: flex;
  
    box-sizing: border-box;
    background-size:cover;
    background-position: top;
    background-image: linear-gradient(rgba(179, 174, 174, 0.486),rgba(128, 128, 128, 0.199),rgba(128, 128, 128, 0.342)),url("https://images.unsplash.com/photo-1557682260-96773eb01377?q=80&w=2029&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
    background-repeat: no-repeat;
    transition: 0.5s;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
.time-card .con1{
    width: 400px;
    height: 400px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
    box-sizing: border-box;
    background-size:cover;
    background-position: top;
   
    
    background-repeat: no-repeat;

}
.time-card .right-con2{
    width: 828px;
    position: relative;
}
.time-card .right-con2 .con2 {
 width: 828px;
 height: 400px;


 display: flex;
 gap: 20px;
 align-items: center;

overflow: hidden;
scroll-behavior: smooth;
scrollbar-width: none;
-ms-overflow-style: none;

}
.time-card .right-con2 .con2 .navigation-btn{
    display: flex;
     justify-content: space-between;
     position: absolute;
     z-index: 2;
     transform: translateY(-50%);
     top: 50%;
     width: 828px;

  
}
.time-card .right-con2 .con2::-webkit-scrollbar{
    display: none;
}

.scroll-btn{
    width: 50px;
    height: 50px;
    font-size: 22px;
    border-radius: 50%;
    border: none;
    background-color: rgb(137, 32, 236);
    color: white;
    cursor: pointer;
    transition: 0.5s;
   

    
 
}
.scroll-btn:hover{
opacity: 0.7;
}



.time-card .right-con2 .con2  a{
    display: flex;
    flex-direction: column;
    align-items: center;
text-align: center;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 200px;
    height: 350px;
    background-color:rgba(255, 255, 255, 0.484);

}



.grid-offer{
    width: 900px;
    height: 440px;

    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
background-color: white;
}

.grid-offer .top{
    width: 100%;
    height: 50px;
    position: relative;
    z-index: 100;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
padding-right: 10px;
background-color: white;


}
.grid-offer .top button{
    height: 40px;
  
}
.MuiTypography-root{
    text-align: center;
}

.grid-offer .bottom{
    position: relative;
    width: 100%;
    height: 350px;
    display: flex;
    align-items: center;
padding: 20px 0px;
background-color: white;


}
.grid-offer .bottom .raju{
 
    scrollbar-width: none;
    scroll-behavior: smooth;
    overflow:hidden;
    width: 100%;
    height: 360px;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(150px,240px));
justify-content: center;
row-gap: 20px;
column-gap: 50px;




}



.grid-offer .bottom .content{
position: absolute;
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;

height: 370px;

}
.grid-offer .bottom .content button{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: none;
    font-size: 22px;
    background-color: blueviolet;
    color: white;
    cursor: pointer;
}

.grid-offer .bottom .content button:hover{
    opacity: 0.6;
}







.billing-container{
    padding: 40px 20px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    width: 800px;
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    
}

.billing-container div{
    display: flex;
    flex-direction: column;
    width: 100%;
    
}
.billing-container div input{
    width: 75%;
    align-self: center;
    padding: 5px;
}
.billing-container div label{
    width: 75%;
    align-self: center;
}

.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 80%;
    object-fit: cover;
  }
  .swiper-pagination-bullets {
    display: none;
}

  .swiper-wrapper .swiper-slide{
    display: flex;
    align-items: center;

    flex-direction: column;
   line-height: 16px;
    width: 200px;
    height: 270px;
    flex-grow: 0;
    flex-shrink: 0;
  
    cursor: pointer;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  }
  .swiper-wrapper{
    display: flex;
    align-items: center;
  }
  .sm-con{
    width: 200px;
    height: 400px;
    background-color: red;
    
  }

.swiping-card h3{
    background-color: rgb(223, 221, 221);
    padding: 10px;
    font-family: "Patua One", serif;
    font-weight: 400;
    font-style: normal;
}
.product-detail{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* width: 1538px; */

}
.product-detail h3{
    background-color: rgb(223, 221, 221);
    padding: 10px;
  
    font-family: "Patua One", serif;
    font-weight: 400;
    font-style: normal;
    width: 100%;
}
.product-detail table{
    border: 1px solid rgb(233, 233, 233);
    align-self: flex-start;
}
.product-detail table tr th{
    width: 150px;
    padding: 15px;
    background-color: rgb(223, 221, 221);
    border: 1px solid rgb(153, 150, 150);

}
.product-detail table tr td{
    width: 200px;
    padding: 15px;
    border: 1px solid rgb(153, 150, 150);
}
.main-container{
    width: 1228px;
  display: flex;
    height: 300px;
    justify-content: center;
    align-items: center;
    gap: 30px;
    flex-wrap: wrap;



}
.main-container .con{
    width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 250px;
    background-color: white;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
.main-container .con img{
    width: 100px;
    height: 100px;
    background-color: rgb(243, 243, 243);
    transition: 0.5s;
    cursor: pointer;
}
.main-container .con img:hover{
    transform: rotate(360deg);
    border-radius: 50%;
    background-color: rgb(245, 128, 128);
}

.about-main {
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

#story{
    text-align: justify;
}
#story h1, #mission h1, #sets-apart h1 ,#team h1 ,#join-community h1 {
    font-size: 1.5em;
}

#sets-apart ul {
    list-style-type: disc;
    padding-left: 20px;
}
.react-photo-album{
    width: 100%;
    
}
#cart-sm{
    display: none;
}
.con-info{
    padding: 5px;
    border-bottom: 3px solid blue;
}
.myorder-container{
    overflow: scroll;
}
.order-card{
    display: flex;
    flex-direction: column;
    width: 1250px;
    max-width: 1250px;
    background-color: white;
    /* height: 300px; */
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
}
#itemnotfound{
    width: 50%;
}

.order-card .top-order{
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.order-card .bottom-order
{

    padding: 10px;

    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 85%;
}
.order-card .bottom-order div{
 flex-grow: 0;
 flex-shrink: 2;
 flex-basis: 300px;
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
}

.right-con2 .con2 .con img{
    width: 150px;
    height: 220px;
    margin-top: 20px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.bottom .raju a{
    text-decoration: none;
    color: black;
}

.grid-sm-card{
    display: flex;
    gap: 10px;
    
}

.grid-sm-card img{
    width: 110px;
    height: 160px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
.leftcontainer{
    margin-top: 20px;
    width: 1228px;
    height: 350px;
    background-color: white;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.leftcontainer .top-heading{
    height: 50px;
    display: flex;
    padding: 0px 20px;
    align-items: center;
  justify-content: space-between;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}
.leftcontainer .top-heading a{
    text-decoration: none;
    font-weight: bolder;
}
.leftcontainer .top-heading a h1{
    font-size: 22px;
    text-decoration: none;
}
.leftcontainer .bottom-thing{
height: 300px;
display: flex;
align-items: center;

}


.swiper-grid-column > .swiper-wrapper{
  
    padding-top: 20px;
}
#carouselExampleAutoplaying{
    width: 1228px;
    margin: 20px 0px;
}


.ratingcontainer{
    width: 80%;
}
#acc-btn{
background-color: rgb(223, 221, 221);
}
.pointer{
    cursor: pointer;
}
.big-new-arrival-container{
  background-color: white;
  width: 1228px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
.big-new-arrival-container h1{
    color: black;
    padding: 5px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
.new-arrival{
    height: 270px;
    width: 100%;
  
  
    display: flex;
    align-items: center;
    overflow-x: hidden;

    scroll-behavior: smooth;
 
}
.new-arrival .arrivalcon{
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 180px;
    height: 250px;
    

}
.new-arrival .arrivalcon  img{
    width: 100% !important;
    height: 250px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.item-page-brief{
    width: 1538px;
}
.policy2-container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 8px;
    text-align: justify;
  }
  .policy2-container .section h2 {
    color: #333;
  }
  .policy2-container  .section p {
    line-height: 1.6;
  }
  .policy2-container  .section {
    margin-bottom: 30px;
  }
  .policy2-container .section  .highlight {
    color: #ff6600;
    font-weight: bold;
  }
  .policy2-container .section .key-points {
    font-weight: bold;
  }
  #otpTimer {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .swiper-button-prev:after, .swiper-button-next:after {
    font-family: "Patua One", serif;
    font-weight: 400;
    font-style: normal;
   font-size: 25px !important;
  background-color: #3468C0;
  font-weight: bolder;
  color: white !important;
  padding: 8px 16px;
  border-radius: 50%;
    text-transform: none !important;
    letter-spacing: 0;
    font-variant: initial;
    line-height: 1;
}

.swiper-button-prev:hover::after, .swiper-button-next:hover::after{
    opacity: 0.7;
} 


.toppublisher-container{
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(150px,150px));
    justify-content: center;
    align-content: center;
    width: 1228px;
    gap: 30px;
}
.publisher-link{
text-decoration: none;
text-align: center;
}
.publisher-link b{
   text-align: center;
   text-wrap: nowrap;
   text-decoration: none;
   color: black;
   font-size: 12px;
}
.toppublisher-container .pub-con{
    height: 150px;
    display: flex;
  
    justify-content: center;
    align-content: center;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.toppublisher-container .pub-con img{
    object-fit: contain;
    width: 100%;
}
.banner1{
    object-fit: contain;
}
.testi-con{
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    display: flex;
padding: 20px;
font-size: 19px;
    align-items: center;
}
.testi-con img{
    padding: 10px;
    border: 3px solid rgb(175, 171, 171);
    width: 150px;
    height: 150px;

border-radius: 50%;
}
.testi-con b{
    font-size: 22px;
    text-decoration:3px grey underline;
    
}
.card-text:last-child{
    font-size: 19px;
}

.term-container{
    max-width: 800px;
    margin: auto;
  }

  .term-container  h1,.term-container  h2 {
    margin-top: 20px;
  }

  .term-container  p {
    margin-bottom: 20px;
  }

  .term-container ul {
    margin-bottom: 20px;
  }

  .term-container ul li {
    margin-bottom: 10px;
  }





.swiper-main-con{
    display:flex !important;
    flex-direction: row !important;
 justify-content: flex-start !important;
 column-gap: 10px !important;
 margin: 0 !important ;

}
.swiper-main-con img{
    object-fit: contain;
    width: 150px;
    
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.swiper-main-con .right-side{
    display: flex !important;
    flex-direction: column !important;
    gap: 10px !important;
    align-items: flex-start !important;
    align-self: flex-start !important;

    
}
.swiper-main-con .right-side b{
    font-size: 22px !important;
    text-align-last: left;
    text-align: start;
}
.swiper-main-con .right-side .red-price{
    color: #e15b64;
}
.swiper-main-con .right-side .green-price{
    color: #046A38;
}
.mySwiper2con{
    height: 400px !important;
}

.swiper-wrapper .swiper-slide{
line-height: 20px !important;
}
.product-detail{
    width: 1538px;
}
.grid-container{
    width: 1228px;
    background-color: white;
}
.grid-container .top-con{
    display: flex;
    align-items: center;
    height: 60px;
    justify-content: space-between;
    padding: 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.grid-container .top-con a,.grid-container .top-con h1{
    text-decoration:  none;
    font-weight: bolder;
    font-size: 18px !important;


}
.grid-container .top-con a h1{
    font-size: 22px !important;
}
.grid-swiper{
    height: 600px !important;
}
.grid-anchor{
    display: flex;
    
    text-align: start;
    color: #000;
    text-decoration: none;
}
.grid-swiper-card img{
width: 200px;

object-fit: contain;
box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.grid-price{
    display: flex;
    flex-direction: column;
    
 
}
.grid-price1{
    
    display: flex;
    gap: 1px;
}

.testimonial-container {
    background-color: #f9f9f9;
    color: #090909;
    border-radius: 15px;
    margin: 20px auto;
    padding: 50px 80px;
    max-width: 1250px;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  }
  
  .fa-quote {
    color: rgba(69, 120, 40, 0.3);
    font-size: 28px;
    position: absolute;
    top: 70px;
  }
  
  .fa-quote-right {
    left: 40px;
  }
  
  .fa-quote-left {
    right: 40px;
  }
  
  .testimonial {
    line-height: 28px;
    text-align: justify;
  }
  
  .user {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
  }
  
  .user .user-image {
    border-radius: 50%;
    height: 75px;
    width: 75px;
    object-fit: cover;
    border:5px solid #54e54f;
  }
  
  .user .user-details {
    margin-left: 10px;
  }
  
  .user .username {
    margin: 0;
  }
  
  .user .role {
    font-weight: normal;
    margin: 10px 0;
  }
  
  .progress-bar {
    background-color: #54e54f;
    height: 4px;
    width: 100%;
    animation: grow 10s linear infinite;
    transform-origin: left;
    margin-bottom: 0.7rem;
  }
  

  @keyframes grow {
    0% {
      transform: scaleX(0);
    }
  }
  
  @media (max-width: 768px) {
    .testimonial-container {
      padding: 20px 30px;
    }
  
    .fa-quote {
      display: none;
    }
  }


  .main{
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.blog-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
  }
  
  .blog-box {

    width: 240px;
    /* height: 300px; */
    padding: 12px;
    background-color: white;
    text-align: center;
    border-radius: 3px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  }
  .blog-box a {
    text-decoration: none;
    color: black;
    
  }
  
  .blog-box img {
    width: 200px;
  }


  .blog-section {
    width: 100%;
    padding: 2rem;
  }
  
  .blog-section .heading {
    text-align: center;
  }
  
  .blog-section .underline {
    width: 150px;
    height: 6px;
    position: relative;
    left:46%;
    background-color: rgb(56, 10, 117);
    margin-bottom: 12px;
  }
  .blog-box h4{
    font-size: 14px !important;
  }
  .infinite-scroll-component{
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(150px,200px));
    gap: 20px;
    justify-content: center;
  }
@media screen and (max-width:1530px) {
    .product-detail{
        width: 1100px;
    }

    .item-page-brief{
        width: 1100px;
    }
    .croul{
    flex-wrap: wrap-reverse;
    }
    
    
}



.imagroup{
    display: flex;
    justify-content:flex-start;
    flex-wrap: wrap;
    align-items: center;
    gap:1.5rem;
    
}


@media screen and (max-width:1200px) {
    .address{
        display: none;
    }
    .social a{
        font-size: 25px;
    }
   .grid-price{
    font-size: 13px;
    align-items: center;
    justify-content: center;
   }
    .grid-anchor{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #000;
        text-decoration: none;
    }
    .grid-container{
        width: 800px;
    }
    .product-detail{
        width: 800px;
    }
    .mySwiper2con{
        height: 650px !important;
    }
    .swiper-main-con{
        display:flex !important;
        flex-direction: column !important;
     justify-content: flex-start !important;
     gap: 10px !important;
     margin: 0 !important ;
    
    }
    .swiper-main-con .right-side{

        justify-content: center !important;
        align-items: center !important;
        padding: 5px;
    }
    .swiper-main-con .right-side b{
        
        font-size: 14px !important;
    }

    .swiper-wrapper{
        row-gap:10px ;
    }
    .toppublisher-container{
        
        width: 800px;
    }
    .crousal{
        width: 800px;
        height: 400px;
    }
    #main-slider-container{
        margin-top: 10px;
        width: 800px !important;
    }
    .big-new-arrival-container{
        width: 800px;
    }
    .banner1,.leftcontainer{
    width: 800px;
    }
    
  
    .time-card{
        width: 800px;
        height: 1000px;
        flex-direction: column;
        background-position: top center;
    
    }
    .time-card .con1{
        width: 100%;
    }
    .time-card .right-con2 .con2{
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fit,minmax(160px,180px));
        justify-content: center;
        padding: 10px 0px;
        height:750px ;
        
    }
    .time-card .right-con2 .con2 .con{
        height: 300px;
    }
    .left-offer-grid{
        display: none;
    }
    .top-cro{
        flex-direction: column;
        align-items: center;
    }
    #carouselExampleDark{
        margin: 0;
        margin: 10px 0px;
        width: 95%;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    }
   
    .swiping-card{
        width: 800px;
    }
    .main-container{
        height: 600px;
    }

}



@media screen and (max-width:920px) {
   
    
    #cart-sm{
        display: block;
    }
}


@media screen and (max-width:1026px){
    .navcontainer2{
        justify-content: space-between;
        padding: 0px 20px;
    }
   .navcontainer2 .right-nav2{
        display: none;
    }
    #search{
        font-size: 20px;
    }
    .nav2-fc b{
        display: none;
    }
  
    .cart-card{
        margin-top: 20px;
        width: 800px;
        background-color: white;
        height: 300px;
    }
  
}

@media screen and (max-width:888px){
   .navcontainer .cart{
        display: none;
    }
    .grid-container{
        width: 600px;
    }
    .product-detail{
        width: 600px;
    }
    .toppublisher-container{
        
        width: 600px;
    }
    .big-new-arrival-container{
        width: 600px;
    }
    .crousalbtn button{
        top: 30%;
        font-size: 30px;
    }
    .crousalbtn #crou-right{
        left: 95%;
    }
    .crousal{
        width: 600px;
        
    }

    .top-cro{
        flex-direction: column;
        align-items: center;
    }
    #carouselExampleDark{
        margin: 0;
        margin: 10px 0px;
        width: 100%;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    }
   

    .banner1,.leftcontainer{
        width: 600px;
        }
   
    .product-add-page{
        flex-wrap: wrap;
    }
    .offer-pack .offer-pack-container div .items{
        margin-top: 20px;
        width: 130px;
        height: 180px;
        border: 1px solid black;
     }
     
    
    .cart-card{
        margin-top: 20px;
        width: 600px;
        background-color: white;
        height: 300px;
    }
    .card-container2{
        width: 600px;
    }
    .time-card{
        width: 600px;
     
        flex-direction: column;
        background-position: top center;
    }
    .time-card .con1{
        width: 100%;
    }
    .time-card .right-con2{
        width: 600px;
    }
    .time-card .right-con2  .con2{
        width: 100%;
        grid-template-columns: repeat(auto-fit,minmax(160px,170px));
    }
    .time-card .right-con2 .con2 .navigation-btn{
        width: 600px;
     
    }
    .grid-offer{
        width: 600px;
    }
    .billing-container{
        width: 600px;
    }
    
    .swiping-card{
        width: 600px;
    }
    #main-slider-container{
        margin-top: 10px;
        width: 600px !important;
    }

}


@media screen and (max-width:624px) {
    .cart-3 span{
        display: none;
    }
    .navchild-3 button span{
        display: none;
    }
    .navcontainer3 .cart-3{
        display: block;
    }
    .grid-swiper-card {
        width: 200px !important;
    }
    .grid-container{
        width: 400px;
    }
    .product-detail{
        width: 400px;
    }

    .toppublisher-container{
        
        width: 400px;
    }
    .lokeshkumar{
        display: none !important;
    }
    .big-new-arrival-container{
        width: 400px;
    }
    .crousalbtn #crou-right{
        left: 92%;
    }
    .crousal .crousali-img img{
        width: 100%;
        height: 300px;
     }
    .crousal{
        width: 400px;
        height: 300px;
    }
    #itemnotfound{
        width: 70%;
    }
    /* .product-detail{
        width: 400px;
    } */

    .bandeges{
        display: none;
       }
      .leftcontainer{
        width: 400px;
        }
   
.product-add-page{
    width: 95%;
}
.product-items{
    width: 100%;
}
#main-slider-container{
    margin-top: 10px;
    width: 500px !important;
}

.offer-pack .offer-pack-container div .items{
    margin-top: 20px;
    width: 100px;
    height: 150px;
    border: 1px solid black;
 }

 
.cart-card{
    margin-top: 20px;
    width: 450px;
    background-color: white;
    height: 300px;
}
.card-container2{
    width: 450px;
}


.time-card{
    width: 400px;

    flex-direction: column;
    background-position: top center;
}
.time-card .right-con2{
    width: 400px;
}
.time-card .con1{
    width: 100%;
}
.time-card .right-con2 .con2{
    width: 100%;
}
.time-card .right-con2 .con2 .navigation-btn{
    width: 400px;
 
}
.grid-offer{
    width: 400px;
}
.grid-offer .bottom .raju{
   
    grid-template-columns: repeat(auto-fit,minmax(180px,150px));
}

    
.billing-container{
    width: 400px;
}
.swiping-card{
    width: 400px;
}

.main-container{
    height: 1400px;
}



}
@media screen and (max-width:480px) {
    .search-container{
        padding: 0;
        grid-template-columns: repeat(auto-fit, minmax(105px, 170px));
    }
    .grid-container .top-con a,.grid-container .top-con h1{
        text-decoration:  none;
        font-weight: bolder;
        font-size: 16px !important;
    
    
    }
    .social a{
        font-size: 20px;
    }
    .navcontainer img{
        width: 170px;
    }
    .grid-container .top-con a h1{
        font-size: 16px !important;
    }
    .product-detail{
        width: 99%;
    }
    .cards-slider-button #left{
        right: 90%;
        top: 40%;
    }
    #main-slider-container{
        margin-right: 15px;
    }
    .cards-slider-button #right{
        left: 94%;
        top: 40%;
    }
   
    .offer-pack .offer-pack-container div{
        column-gap: 0px;
    }
    #main-slider-container{
        margin-top: 10px;
        width: 400px !important;
    }
    .testi-head{
        width: 400px;
        font-size: 36px !important;
        border-bottom: 10px solid rgb(81, 81, 221);
        border-bottom-right-radius: 23%;
        
    }
    .product-items{
        grid-template-columns: repeat(auto-fit, minmax(105px, 180px));
    }
    .cart-card{
        margin-top: 20px;
        width: 380px;
        background-color: white;
        height: 320px;
    }
    .cart-card img{
        width: 170px;
        height: 100%;
    }
    .content h3{
        font-size: 10px;
    }
  
 
    .fw-bolder{
        font-size: 18px !important;
    }
    .card-container2{
        width: 380px;
    }


    .imagroup{
        margin-left: 30px;
    }
    .banner1
    {
        width: 400px;
    }
    .swiper-slide{
        width: 170px !important;
        
        /* margin-right: 5px !important; */
    }
    
}




@media screen and (max-width:400px) {
    .infinite-scroll-component{
        display: grid;
        grid-template-columns: repeat(auto-fit,minmax(150px,163px));
        gap: 20px;
        justify-content: center;
      }
    .navchild-3 button{
        font-size: 15px;
    }
    .grid-container .top-con a,.grid-container .top-con h1{
        text-decoration:  none;
        font-weight: bolder;
        font-size: 17px !important;
    
    
    }
    .grid-container .top-con a h1{
        font-size: 16px !important;
    }
    .grid-container{
        width: 99%;
    }
  
    .toppublisher-container{
        
        width: 99%;
    }
    .big-new-arrival-container{
        width: 99%;
    }
    .crousal{
        width: 350px;
    }
    /* .product-detail{
        width: 350px;
    } */
    .main-container .con{
        width: 300px;
        height: 300px;
    }

    .banner1,.leftcontainer{
        width: 99%;
        }
    .box{
        width:370px ;
        }
    .time-card{
        width: 99%;
      
        flex-direction: column;
        background-position: top center;
    }
    .time-card .right-con2{
        width: 100%;
    }
    .time-card .con1{
        width: 100%;
    }
    .time-card .right-con2 .con2{
        width: 100%;
    }
    .time-card .right-con2 .con2 .navigation-btn{
        width: 370px;
     
    }
    .grid-offer{
        width: 350px;
    }
    .grid-offer .bottom div{
        grid-template-columns: repeat(auto-fit,minmax(130px,150px));
    }

    .billing-container{
        width: 350px;
    }
    .billing-container div input{
        width: 100%;
    }
    .billing-container div label{
        width: 100%;
    }
    .swiping-card{
        width: 350px;
    }
    #main-slider-container{
        margin-top: 10px;
        width: 350px !important;
    }
  
    .testi-head{
        width: 350px;
        font-size: 36px !important;
        border-bottom: 10px solid rgb(81, 81, 221);
        border-bottom-right-radius: 23%;
        
    }
  

}

@keyframes ldio-cqs6a34ctgs-1 {
    0% { transform: rotate(0deg) }
   50% { transform: rotate(-45deg) }
  100% { transform: rotate(0deg) }
}
@keyframes ldio-cqs6a34ctgs-2 {
    0% { transform: rotate(180deg) }
   50% { transform: rotate(225deg) }
  100% { transform: rotate(180deg) }
}
.ldio-cqs6a34ctgs > div:nth-child(2) {
  transform: translate(-15px,0);
}
.ldio-cqs6a34ctgs > div:nth-child(2) div {
  position: absolute;
  top: 41.6px;
  left: 41.6px;
  width: 124.80000000000001px;
  height: 62.400000000000006px;
  border-radius: 124.80000000000001px 124.80000000000001px 0 0;
  background: #f8b26a;
  animation: ldio-cqs6a34ctgs-1 1s linear infinite;
  transform-origin: 62.400000000000006px 62.400000000000006px
}
.ldio-cqs6a34ctgs > div:nth-child(2) div:nth-child(2) {
  animation: ldio-cqs6a34ctgs-2 1s linear infinite
}
.ldio-cqs6a34ctgs > div:nth-child(2) div:nth-child(3) {
  transform: rotate(-90deg);
  animation: none;
}@keyframes ldio-cqs6a34ctgs-3 {
    0% { transform: translate(197.6px,0); opacity: 0 }
   20% { opacity: 1 }
  100% { transform: translate(72.8px,0); opacity: 1 }
}
.ldio-cqs6a34ctgs > div:nth-child(1) {
  display: block;
}
.ldio-cqs6a34ctgs > div:nth-child(1) div {
  position: absolute;
  top: 95.68px;
  left: -8.32px;
  width: 16.64px;
  height: 16.64px;
  border-radius: 50%;
  background: #e15b64;
  animation: ldio-cqs6a34ctgs-3 1s linear infinite
}
.ldio-cqs6a34ctgs > div:nth-child(1) div:nth-child(1) { animation-delay: -0.67s }
.ldio-cqs6a34ctgs > div:nth-child(1) div:nth-child(2) { animation-delay: -0.33s }
.ldio-cqs6a34ctgs > div:nth-child(1) div:nth-child(3) { animation-delay: 0s }
.loadingio-spinner-bean-eater-bmo59f5ir8p {
  width: 208px;
  height: 208px;
  display: inline-block;
  overflow: hidden;
  background: #f3f3f3;
}
.ldio-cqs6a34ctgs {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-cqs6a34ctgs div { box-sizing: content-box; }

@keyframes ldio-mia0tallpll {
    0% { transform: translate(-50%,-50%) rotate(0deg); }
    100% { transform: translate(-50%,-50%) rotate(360deg); }
  }
  .ldio-mia0tallpll div {
    position: absolute;
    width: 60px;
    height: 60px;
    border: 10px solid #fff200;
    border-top-color: transparent;
    border-radius: 50%;
  }
  .ldio-mia0tallpll div {
    animation: ldio-mia0tallpll 1s linear infinite;
    top: 50px;
    left: 50px
  }
  .loadingio-spinner-rolling-iria6x322e {
    width: 99px;
    height: 99px;
    display: inline-block;
    overflow: hidden;
    background: transparent;
    
  }
  .ldio-mia0tallpll {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(0.99);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-mia0tallpll div { box-sizing: content-box; }

  .swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    width: 100%;
    text-align: center;
    font-size: 18px;
    background: #fff;
  margin: 0px 0px ;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100% !important;
    height:180px;
    object-fit: contain;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  }

  @import url("https://fonts.googleapis.com/css?family=Montserrat");

  *{
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      font-family: "Patua One", serif;
      font-weight: 400;
      font-style: normal;
  }
  .blog-mainContainer{
    margin: 10px 0px;
      width: 100%;
      background-color:blanchedalmond;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding:12px;
      
  }
  /* .blogmain-box {
    width: 240px;
    height: 300px;
    padding: 12px;
    background-color: white;
    text-align: center;
    border-radius: 3px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  } */
  
  .blogmain-box img {
    width: 400px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  }
  .heading-text{
    font-size: 30px !important;
  }
  
  .blogmain-text-container{
     min-width:100%;
     display:flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
  }
  
  .blogmain-text{
      width:90%;
      text-align: justify;
  }
  
  .blogmain-text span{
      color:black;
  }
  
  .blogmain-text p{
      line-height: 2rem;
      letter-spacing:1.2px;
      margin-top:5px;
      color:rgb(84, 80, 80);
  }
  